<template>
  <div class="report rectBox boxb">
    <div class="labelHdader disfr jsb">
      <span>数据范围</span>
    </div>
    <el-form class="searchForm" size="small">
      <el-row>
        <el-col :span="24" class="disfr ac">
          <el-form-item>
            <el-select
              v-model="searchForm.dataTime"
              class="dataTime"
              @change="changeDataTime"
            >
              <el-option
                v-for="item in dataTimes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="searchForm.date"
              @change="changeDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="disfr ac flexWrap">
          <el-form-item>
            <el-select v-model="searchForm.reportType" class="dataType">
              <el-option
                v-for="item in reportTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="请输入搜索内容"
              v-model="searchKeyword"
              class="input-with-select"
              style="width: 300px"
            >
              <el-select
                style="width: 100px"
                slot="prepend"
                v-model="searchKeywordCode"
                placeholder="请选择类型"
              >
                <el-option label="应用名称" value="title"></el-option>
                <el-option label="包名" value="packageName"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.businessUserId"
              @change="changeMultiple($event, 'businessUserId')"
              multiple
              clearable
              collapse-tags
              class="task"
              filterable
              placeholder="商务"
            >
              <el-option
                v-for="(item, index) in businessNameList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.userIdList"
              @change="changeMultiple($event, 'userIdList')"
              multiple
              clearable
              collapse-tags
              class="app"
              filterable
              placeholder="开发者"
            >
              <el-option
                v-for="item in devloperList"
                :key="item.userId"
                :label="item.companyName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.countryCode"
              @change="changeMultiple($event, 'countryCode')"
              class="task"
              placeholder="国家定向"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="item in languageList"
                :key="item.countryCode"
                :label="item.countryName"
                :value="item.countryCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="padding: 10px 0">
            <el-button type="primary" class="formItem" @click="searchFn"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="taskStatistics mb20">
      <div class="rectBox disfr taskBox boxb">
        <div
          class="flex1 fc jc task tc"
          v-for="(item, index) in taskHeaderList"
          :key="index"
        >
          <div class="moneyTxt fz26">
            <span v-if="index == 2 || index == 4"
              >{{ item.value | numChange }}%</span
            >
            <span v-else>{{ item.value }}</span>
          </div>
          <div class="label">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="chart" ref="chart"></div>

    <div class="seriesBox disfr ac jsb">
      <div class="disfr ac">
        <span>筛选维度：</span>
        <el-checkbox-group v-model="checkSeries" @change="changeSeries">
          <template v-for="(item, index) in seriesList">
            <el-checkbox
              v-if="!['应用包名'].includes(item.label)"
              :key="index"
              :label="item.key"
            >
              {{ item.label }}
            </el-checkbox>
          </template>
        </el-checkbox-group>
      </div>
    </div>

    <PageTable
      id="statisticsTable"
      style="width: 100%"
      class="pageTable"
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
    </PageTable>
  </div>
</template>

<script>
import { costTypeList, MAXSIZE } from "@/common/constant/constant.js";
import PageTable from "@/components/PageTable.vue";
const dataEcharts = {
  myCharts: null,
};
import { getCpsReportList, getCpsReportStatistics } from "./indexServe.js";
import {
  getAllAppStatementList,
  getBusinessList,
  cmsCountryAll,
} from "@/common/js/api";
import { getDeveloperUserList } from "@/views/business/developer/putinDeveloperList/indexServe.js";

export default {
  name: "cpsReport",
  components: { PageTable },
  data() {
    return {
      costTypeList,
      searchKeyword: "",
      searchKeywordCode: "title",
      searchForm: {
        source: [""],
        dataTime: 1,
        date: [],
        reportType: 2,
        envType: 0,
        costType: 1,
        countryCode: [""],
        taskIdList: [""],
        appIdList: [""],
        userIdList: [""],
        ployIdList: [""],
        ideaIdList: [""],
        businessUserId: [""],
      },
      value: [],
      dataTimes: [
        { label: "本周", value: 1, fn: "nowWeek" },
        { label: "本月", value: 2, fn: "nowMonth" },
        { label: "上月", value: 3, fn: "lastMonth" },
      ],
      reportTypes: [
        { label: "合计", value: 1 },
        { label: "分天", value: 2 },
      ],
      appList: [{ appName: "全部应用", appName1: "" }],
      taskList: [{ taskName: "全部任务", id: "" }],
      devloperList: [{ companyName: "全部开发者", userId: "" }],
      taskHeaderList: [
        { name: "下载次数", value: 0, key: "downloadPv" },
        { name: "安装次数", value: 0, key: "installPv" },
      ],
      strategyList: [{ ployName: "全部策略", id: "" }],
      originalityList: [{ ideaName: "全部创意", id: "" }],
      businessNameList: [{ name: "全部商务", id: "" }],
      chartOptions: {
        tooltip: { trigger: "axis" },
        grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
        toolbox: { feature: {} },
        xAxis: { type: "category", boundaryGap: false, data: [] },
        yAxis: [
          { name: "下载次数", type: "value" },
          { name: "安装次数", type: "value" },
        ],
        series: [
          {
            name: "下载次数",
            type: "line",
            yAxisIndex: 0,
            data: [],
            dataKey: "downloadPv",
          },
          {
            name: "安装次数",
            type: "line",
            yAxisIndex: 1,
            data: [],
            dataKey: "installPv",
          },
        ],
      },
      seriesList: [
        // 筛选数据列表
        {
          label: "商务",
          key: "isBusinessName",
          showSeries: {
            key: "businessName",
            label: "商务",
          },
        },
        {
          label: "开发者",
          key: "isUserShow",
          showSeries: {
            key: "companyName",
            label: "开发者",
          },
        },
        {
          label: "应用名称",
          key: "isAppShow",
          showSeries: {
            key: "title",
            label: "应用名称",
          },
        },
        {
          label: "应用包名",
          key: "isAppShow",
          showSeries: {
            key: "packageName",
            label: "应用包名",
          },
        },
      ],
      initColumns: [
        { key: "downloadPv", label: "下载次数" },
        { key: "installPv", label: "安装次数" },
        { key: "conversionRate", label: "下载安装转化率" },
      ],
      columns: [],
      tableData: { list: [], size: 10, page: 1, total: 0 },
      languageList: [{ countryName: "全部地区", countryCode: "" }],
      chartInstance: null,
      checkSeries: [],
      formKey: [
        "userIdList",
        "appIdList",
        "taskIdList",
        "ployIdList",
        "ideaIdList",
        "businessUserId",
        "countryCode",
      ],
      listKey: ["getDevList", "getAppList"],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate() - 1);
              end.setDate(end.getDate() - 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "前三天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate() - 3);
              end.setDate(end.getDate() - 1);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  async mounted() {
    getBusinessList({}).then((res) => {
      if (res && res.code === 0) {
        this.businessNameList = [
          {
            name: "全部商务",
            id: "",
          },
        ].concat(res.data);
      }
    });
    this.getLanguageList();
    await this.init();
    dataEcharts.myCharts.on("legendselectchanged", (params) => {
      let { name } = params;
      let { legend, yAxis } = this.chartOptions;
      yAxis[1].name = name;
      for (let i in legend.selected) {
        legend.selected[i] = false;
        if (i === name) {
          legend.selected[i] = true;
        }
      }
      this.chartOptions.legend = legend;
      this.chartOptions.yAxis = yAxis;
      this.initCharts();
    });
  },
  methods: {
    async init() {
      this.searchForm.date = this.nowWeek();
      dataEcharts.myCharts = this.echarts.init(this.$refs.chart);
      await Promise.all([this.getAppList(), this.getDevList()]);
      await Promise.all([this.getReportList(), this.getReportStatistics()]);
      this.initCharts();
    },
    initCharts() {
      let initOption = JSON.parse(JSON.stringify(this.chartOptions));
      dataEcharts.myCharts.setOption(initOption);
    },
    filterParamsList(key, listKey, listChildKey) {
      let list = this.searchForm[key].filter((a) => a !== "");
      if (list.length) return list;
      list = this[listKey]
        .filter((a) => a[listChildKey] !== "")
        .map((a) => a[listChildKey]);
      if (key == "userIdList") return [];
      return list;
    },
    async getAppList() {
      let res = await getAllAppStatementList({
        userId: this.filterParamsList(
          "userIdList",
          "devloperList",
          "userId"
        ).join(),
      });
      if (res.code !== 0) return;
      let curAppNameList = res.data.list.map((item) => ({
        ...item,
        appName: item.title,
        appName1: item.appId,
      }));
      this.appList = [{ appName: "全部应用", appName1: "" }].concat(
        curAppNameList
      );
    },
    async getDevList() {
      let res = await getDeveloperUserList({
        backendType: 1, //平台分类 1:开发者 ，2:代理商，3:推广后台
        identityStatus: 3,
        size: 30000,
      });
      if (res.code !== 0) return;
      let arr = res.data.list || [];
      const newArray = arr
        .filter((item) => item.companyName)
        .map((item) => ({ userId: item.id, companyName: item.companyName }));
      this.devloperList = [{ companyName: "全部开发者", userId: "" }].concat(
        newArray
      );
    },
    changeSeries(e) {
      this.getReportList();
    },
    async getReportList(args) {
      let params = this.filterParams();
      params = { ...params, ...args };
      if (params.reportType == 3 && (!params.endTime || !params.startTime)) {
        this.$message.error(`按照小时格式查询时需选择时间段`);
        return;
      }
      this.seriesList.forEach((a) => {
        params[a.key] = 0;
        if (this.checkSeries.find((b) => b === a.key)) {
          params[a.key] = 1;
        }
      });
      this.columns = this.initColumns;
      const res = await getCpsReportList(params);
      if (res.code !== 0) return;
      const data = res.data;
      this.tableData.list = (data.list || []).map((item) => {
        item.pt = this.searchForm.reportType == 1 ? "合计" : item.pt;
        return item;
      });
      this.tableData.total = data.total;
      this.tableData.page = params.page;
      this.tableData.size = params.size;

      const arr = JSON.parse(JSON.stringify(this.initColumns));
      const seriesList = JSON.parse(JSON.stringify(this.seriesList));
      seriesList.reverse().forEach((a) => {
        if (this.checkSeries.find((b) => b === a.key)) {
          arr.unshift(a.showSeries);
        }
      });
      arr.unshift({ key: "pt", label: "日期" });
      this.columns = arr;
    },
    async getReportStatistics(args) {
      let params = this.filterParams();
      params = { ...params, ...args };
      if (params.reportType == 3 && (!params.endTime || !params.startTime)) {
        this.$message.error(`按照小时格式查询时需选择时间段`);
        return;
      }
      const res = await getCpsReportStatistics(params);
      if (res.code !== 0) return;
      let data = res.data;
      let { allStatistic = {}, list } = data;
      this.taskHeaderList.forEach((a) => {
        a.value = allStatistic[a.key] || 0;
      });
      let { xAxis, series } = this.chartOptions;
      xAxis.data = [];
      series.forEach((a) => {
        a.data = [];
      });
      if (!list) list = [];
      list.forEach((a) => {
        xAxis.data.push(
          params.reportType == 3 ? a.pt + "_" + a.hourKey + "点" : a.pt
        );
        series.forEach((b) => {
          b.data.push(a[b.dataKey]);
        });
      });
      this.chartOptions.xAxis = xAxis;
      this.chartOptions.series = series;
      this.initCharts();
    },
    getDateStr(now) {
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;
      return year + "-" + month + "-" + day;
    },
    changeDataTime(val) {
      let date = this[this.dataTimes.find((a) => a.value === val).fn]();
      // 获取本周本月 上月日期
      this.searchForm.date = date;
    },
    nowWeek() {
      var now = new Date();
      var nowDayOfWeek = now.getDay() == 0 ? 6 : now.getDay() - 1;
      var startTime = this.getDateStr(
        new Date(now.getTime() - nowDayOfWeek * 24 * 60 * 60 * 1000)
      );
      var endTime = this.getDateStr(now);
      return [startTime, endTime];
    },
    nowMonth() {
      var now = new Date();
      var nowDay = now.getDate() - 1;
      var startTime = this.getDateStr(
        new Date(now.getTime() - nowDay * 24 * 60 * 60 * 1000)
      );
      var endTime = this.getDateStr(now);
      return [startTime, endTime];
    },
    lastMonth() {
      const nowDays = new Date();
      let year = nowDays.getFullYear();
      let month = nowDays.getMonth();
      let days = new Date(year, month, 0).getDate();
      if (month == 0) {
        month = 12;
        year = year - 1;
      }
      if (month < 10) month = "0" + month;
      const startDate = `${year}-${month}-01`;
      const endDate = `${year}-${month}-${days}`;
      return [startDate, endDate];
    },
    filterParams() {
      let params = { ...this.searchForm };
      params.page = this.tableData.page;
      params.size = this.tableData.size;

      if (this.searchKeyword.trim() !== "") {
        params[this.searchKeywordCode] = this.searchKeyword;
      }
      params.startTime = params.date && params.date[0];
      params.endTime = params.date && params.date[1];
      params.userIdList = params.userIdList.filter((a) => a !== "");
      params.countryList = params.countryCode.filter((a) => a !== "");
      params.appIdList = params.appIdList.filter((a) => a !== "");
      params.taskIdList = params.taskIdList.filter((a) => a !== "");
      params.ployIdList = params.ployIdList.filter((a) => a !== "");
      params.ideaIdList = params.ideaIdList.filter((a) => a !== "");
      params.source = params.source.filter((a) => a !== "");
      params.manageIdList = params.businessUserId.filter((a) => a !== "");
      delete params.date;
      delete params.dataTime;
      delete params.countryCode;
      delete params.businessUserId;
      return params;
    },
    handleChangePage({ page, size }) {
      this.tableData.page = page;
      this.tableData.size = size;
      this.getReportList();
    },
    searchFn() {
      this.getReportList();
      this.getReportStatistics();
    },
    async changeDate(val) {
      this.searchForm.dataTime = [];
      this.dataTimes.forEach((a) => {
        let fnDate = this[a.fn]();
        if (val && fnDate[0] === val[0] && fnDate[1] === val[1]) {
          this.searchForm.dataTime = a.value;
        }
      });
    },
    async getLanguageList() {
      const res = await cmsCountryAll();
      this.languageList = [{ countryName: "全部地区", countryCode: "" }].concat(
        res.data
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../common/css/common.scss";
.report {
  height: auto;
  padding: 0 16px;
  .labelHdader {
    @extend .fz16;
    padding-right: 64px;
    line-height: 72px;
    margin-bottom: 6px;
    .headerOperation {
      .Oper {
        @extend .pointer;
        > img {
          @extend .inlinebox;
          @include rect(18px);
          margin-right: 6px;
        }
        + .Oper {
          margin-left: 42px;
        }
      }
    }
  }
  .searchForm {
    line-height: 32px;
    margin-bottom: 40px;
    .el-form-item {
      margin-bottom: 0;
      height: 32px;
    }
    /deep/.dataTime,
    /deep/.dataType {
      width: 98px;
    }
    /deep/.date {
      width: 296px;
    }
    /deep/ .app,
    .task,
    .formItem {
      width: 170px;
    }
    /deep/.el-form-item + .el-form-item {
      margin-left: 16px;
    }
    /deep/.el-select__tags {
      .el-tag {
        max-width: 70%;
      }
    }
  }
  .taskStatistics {
    height: 100px;
    margin-bottom: 61px;
    .taskBox {
      // .task:first-child {
      //   width: 205px;
      //   text-align: left;
      //   flex: none;
      // }
      .task + .task {
        border-left: 1px solid #e4e4e4;
      }
    }
    .moneyTxt {
      margin-bottom: 10px;
    }
  }
  .chart {
    height: 430px;
    @include mb(50);
  }
  .seriesBox {
    height: 40px;
    margin-bottom: 20px;
  }
  .pageTable {
    margin-bottom: 100px;
  }
}
</style>
