import request from "@/config/serve.js";
// 查询
export function getCpsReportList(params) {
  return request("post", "/cpsreport/list", {
    deleted: false,
    ...params,
  });
}

export function getCpsReportStatistics(params) {
  return request("post", "/cpsreport/statistics", {
    deleted: false,
    ...params,
  });
}
